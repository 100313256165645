<template>
    <div>
        <div class="background-animation"></div>

        <div class="loader" v-if="isLoading">
            <img src="@/assets/loader.gif">
        </div>
        <!-- Sounds -->
        <!-- <audio v-if="audio_1" style="position: absolute;visibility: hidden;" class="test" autoplay controls>
            <source src="../../../assets/sounds/cheer.mp3">
        </audio> -->
        <!-- Course Inner -->
        <div class="container mt-100 mb-100">
            <!-- Row -->
            <div class="row gy-3">
                <!-- Col Headed -->
                <div class="col-12">
                    <!-- Col Header Card -->
                    <div class="card">
                        <div class="card-body p-0 overflow-hidden">

                            <div class="row g-3">
                                <!-- Col -->
                                <div class="col-12 h-100">
                                    <div class="course-image overflow-hidden">
                                        <!-- <img :src="courses_by_id.file" alt="course image"> -->
                                        <div class="position-relative">
                                            <div class="video position-absolute top-0 h-100 w-100">
                                                <video class="w-100" style="height: 100%;object-fit: cover;" loop=""
                                                    muted="" autoplay="" playsinline="">
                                                    <source v-if="$route.params.id == '5'"
                                                        src="https://academy.dk.ge/video.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '5'"
                                                        src="https://academy.dk.ge/video.mp4" type="video/mp4">

                                                    <source v-if="$route.params.id == '21'"
                                                        src="https://academy.dk.ge/video_1.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '21'"
                                                        src="https://academy.dk.ge/video_1.mp4" type="video/mp4">

                                                    <source v-if="$route.params.id == '25'"
                                                        src="https://academy.dk.ge/video_2.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '25'"
                                                        src="https://academy.dk.ge/video_2.mp4" type="video/mp4">

                                                    <source v-if="$route.params.id == '27'"
                                                        src="https://academy.dk.ge/video_3.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '27'"
                                                        src="https://academy.dk.ge/video_3.mp4" type="video/mp4">

                                                    <source v-if="$route.params.id == '29'"
                                                        src="https://academy.dk.ge/video_4.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '29'"
                                                        src="https://academy.dk.ge/video_4.mp4" type="video/mp4">

                                                    <source v-if="$route.params.id == '30'"
                                                        src="https://academy.dk.ge/video_5.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '30'"
                                                        src="https://academy.dk.ge/video_5.mp4" type="video/mp4">

                                                    <source v-if="$route.params.id == '31'"
                                                        src="https://academy.dk.ge/video_6.mp4" type="video/mp4">
                                                    <source v-if="$route.params.id == '31'"
                                                        src="https://academy.dk.ge/video_6.mp4" type="video/mp4">
                                                </video>
                                            </div>
                                            <div class="home-height" style="height: 50vh;"></div>
                                            <h1 class="video_text">გახდი შენი თავის საუკეთესო ვერსია</h1>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Col -->

                                <!-- Col -->
                                <div v-if="1 != 1" class="col-12 col-md-4">
                                    <!-- Card -->
                                    <form v-if="courses_by_id.registration_type == 'online'"
                                        class="card border-0 bg-light position-sticky top-300px">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between aling-items-center">
                                                <h6 class="m-0 d-flex align-items-center">
                                                    <span v-if="courses_by_id.demo_status === 1" class="d-block">პირველი
                                                        თვე</span>

                                                    <span v-else class="d-block">
                                                        თვეში
                                                    </span>
                                                </h6>

                                                <span class="fw-bold fs-3">{{ courses_by_id.price }} ₾</span>
                                            </div>
                                            <small
                                                class="text-danger text-center d-block mt-2 border bg-white rounded p-2"
                                                v-if="courses_by_id.demo_status === 1">სწავლის ღირებულება არის პირველი
                                                თვის განმავლობაში 80 ლარი ხოლო მომდევნო თვიდან თვეში 300 ლარი.</small>


                                            <!-- Buy -->
                                            <div class="border-top mt-3 pt-4" v-if="!user">
                                                <button type="button" class="btn btn-my-primary mt-4 w-100"
                                                    @click="calendar()">შეხვდი მწვრთნელს</button>

                                                <button @click="buy()" type="button"
                                                    class="btn btn-my-success mt-2 w-100">კურსის შეძენა </button>
                                                <div v-if="1 != 1"
                                                    class="d-flex justify-content-between align-items-center mt-3">
                                                    0% განვადება <img src="../../../assets/image/tbc.svg" width="100">
                                                </div>
                                            </div>
                                            <!-- End Buy -->

                                            <!-- Payments -->
                                            <div class="mt-3 pt-3 border-top" v-if="user">
                                                <!-- Title -->
                                                <h6>გადახდის მეთოდი</h6>
                                                <!-- End Title -->

                                                <!-- payment item -->
                                                <div
                                                    class="d-flex justify-content-start align-items-center mt-2 mb-2 column-gap-1">
                                                    <input v-model="payment.pay" id="card" type="radio" name="cards"
                                                        value="with_card">
                                                    <label
                                                        class="d-flex justify-content-between align-items-center w-100"
                                                        for="card">
                                                        <p class="card-text mb-0">ონლაინ გადახდა</p>
                                                        <div class="d-flex align-items-center column-gap-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                height="30" viewBox="0 0 80 80">
                                                                <g transform="translate(0 0.446)">
                                                                    <g id="Group_2497" data-name="Group 2497"
                                                                        transform="translate(2.439 16.745)">
                                                                        <path id="Path_3314" data-name="Path 3314"
                                                                            d="M1930.809,978.125v-.905h-.234l-.283.62-.282-.62h-.235v.905h.166v-.679l.255.588h.173l.254-.588v.682h.167Zm-1.493,0v-.75h.3v-.153h-.77v.153h.3v.75Z"
                                                                            transform="translate(-1856.211 -940.415)"
                                                                            fill="#f79410"></path>
                                                                        <path id="Path_3315" data-name="Path 3315"
                                                                            d="M750.079,168.758H729.711V132.15h20.368Z"
                                                                            transform="translate(-702.224 -127.172)"
                                                                            fill="#ff5f00"></path>
                                                                        <path id="Path_3316" data-name="Path 3316"
                                                                            d="M28.776,23.28a23.244,23.244,0,0,1,8.891-18.3,23.281,23.281,0,1,0,0,36.608,23.242,23.242,0,0,1-8.891-18.3"
                                                                            transform="translate(0.001 0.001)"
                                                                            fill="#eb001b"></path>
                                                                        <path id="Path_3317" data-name="Path 3317"
                                                                            d="M1037.771,23.281a23.281,23.281,0,0,1-37.666,18.3,23.284,23.284,0,0,0,0-36.608,23.282,23.282,0,0,1,37.666,18.3"
                                                                            transform="translate(-962.451 0.002)"
                                                                            fill="#f79e1b"></path>
                                                                    </g>
                                                                    <rect id="Rectangle_996" data-name="Rectangle 996"
                                                                        width="80" height="80"
                                                                        transform="translate(0 -0.446)" fill="none">
                                                                    </rect>
                                                                </g>
                                                            </svg>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                height="30" viewBox="0 0 80 80">
                                                                <g id="Group_2494" data-name="Group 2494"
                                                                    transform="translate(1.914 26.816)">
                                                                    <g id="Group_2493" data-name="Group 2493"
                                                                        transform="translate(0 0)">
                                                                        <path id="Path_3305" data-name="Path 3305"
                                                                            d="M44.15,75.748h-6.2l3.872-23.8h6.2Zm-11.41-23.8L26.832,68.316l-.7-3.525h0l-2.086-10.7a2.653,2.653,0,0,0-2.94-2.144H11.341l-.115.4a23.183,23.183,0,0,1,6.483,2.721l5.383,20.68h6.457l9.859-23.8Zm48.743,23.8h5.691l-4.961-23.8H77.23a2.847,2.847,0,0,0-2.862,1.774l-9.244,22.03h6.461l1.292-3.535h7.877Zm-6.818-8.42,3.255-8.908,1.833,8.908Zm-9.043-9.66.884-5.112a17.885,17.885,0,0,0-5.574-1.037c-3.075,0-10.379,1.344-10.379,7.879,0,6.149,8.571,6.229,8.571,9.457s-7.688,2.651-10.226.615l-.92,5.345a17.367,17.367,0,0,0,7,1.344c4.22,0,10.608-2.19,10.608-8.149,0-6.189-8.641-6.766-8.641-9.457s6.029-2.345,8.687-.884Z"
                                                                            transform="translate(-11.226 -51.519)"
                                                                            fill="#2566af"></path>
                                                                    </g>
                                                                    <path id="Path_3306" data-name="Path 3306"
                                                                        d="M26.2,65.106l-2.1-10.756A2.665,2.665,0,0,0,21.154,52.2H11.341l-.115.4a24.128,24.128,0,0,1,9.242,4.644A18.651,18.651,0,0,1,26.2,65.106Z"
                                                                        transform="translate(-11.226 -51.772)"
                                                                        fill="#e6a540"></path>
                                                                </g>
                                                                <rect id="Rectangle_994" data-name="Rectangle 994"
                                                                    width="80" height="80" fill="none"></rect>
                                                            </svg>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                height="30" viewBox="0 0 80 80">
                                                                <g transform="translate(0 -0.277)">
                                                                    <g id="Group_2495" data-name="Group 2495"
                                                                        transform="translate(1.954 12.71)">
                                                                        <path id="Path_3307" data-name="Path 3307"
                                                                            d="M4.754,41.025H71.3a4.754,4.754,0,0,1,4.754,4.754V90.94A4.754,4.754,0,0,1,71.3,95.694H4.754A4.754,4.754,0,0,1,0,90.94V45.779a4.754,4.754,0,0,1,4.754-4.754Z"
                                                                            transform="translate(0 -41.025)"
                                                                            fill="#26a6d1"></path>
                                                                        <path id="Path_3308" data-name="Path 3308"
                                                                            d="M25.769,113.966l-7.534,16.268h9.017l1.119-2.593h2.557l1.119,2.593h9.928v-1.981l.885,1.981H48l.885-2.022v2.022H69.518l2.511-2.539,2.352,2.539,10.6.022-7.557-8.11,7.557-8.18H74.547L72.1,116.445l-2.277-2.479H47.382l-1.929,4.2-1.975-4.2H34.464v1.9l-1-1.9Zm1.744,2.311h4.395l5,11.03v-11.03h4.814l3.858,7.91,3.559-7.91h4.792v11.675H51l-.023-9.148-4.229,9.145h-2.6L39.87,118.8v9.148H33.859l-1.139-2.616H26.579l-1.135,2.616H22.23Zm29.085,0H68.454l3.627,3.809,3.744-3.809h3.627l-5.511,5.866,5.511,5.8H75.66l-3.627-3.866-3.764,3.866H56.6V116.277ZM29.65,118.251l-2.023,4.663h4.047Zm29.875.441v2.13h6.467V123.2H59.528v2.327h7.254l3.37-3.428-3.227-3.409h-7.4Z"
                                                                            transform="translate(-13.582 -94.775)"
                                                                            fill="#fff"></path>
                                                                    </g>
                                                                    <rect id="Rectangle_995" data-name="Rectangle 995"
                                                                        width="80" height="80"
                                                                        transform="translate(0 0.277)" fill="none">
                                                                    </rect>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                                <select disabled class="w-100 border-secondary rounded cursor-pointer"
                                                    style="border: 1px solid #dfdfdf!important;padding: 10px;border-radius: 13px!important;height:43px;"
                                                    v-if="payment.pay === 'with_card'" v-model="payment.subscribtion">
                                                    <option selected value="monthly">თვეში</option>
                                                </select>
                                                <!-- End payment item -->

                                                <!-- payment item -->
                                                <div v-if="1 != 1"
                                                    class="d-flex justify-content-start align-items-center mt-3 column-gap-1">
                                                    <input v-model="payment.pay" id="bank" type="radio" name="cards"
                                                        value="with_bank">
                                                    <label
                                                        class="d-flex justify-content-between align-items-center w-100"
                                                        for="bank">
                                                        <p class="card-text mb-0">თიბისი განვადება</p>
                                                        <img src="../../../assets/image/tbc.svg" width="100">
                                                    </label>
                                                </div>
                                                <!-- End payment item -->
                                                <button type="button" class="btn btn-my-primary mt-4 w-100"
                                                    @click="calendar()">შეხვდი მწვრთნელს <i
                                                        class="bi bi-calendar2-check ms-2"></i></button>
                                                <div class="alert alert-danger text-center mt-4"
                                                    v-if="cart_error == 'error_cart'">თქვენ უკვე სარგებლობთ მოცემული
                                                    მომსახურეობით.</div>
                                                <button :disabled="payment.pay !== 'with_card'" @click="add();"
                                                    type="button" class="btn btn-my-success w-100 mt-3">ონლაინ გადახდა
                                                    <i class="bi bi-rocket"></i></button>
                                                <button v-if="1 != 1" :disabled="payment.pay !== 'with_bank'"
                                                    class="btn btn-my-primary w-100 mt-1">განვადება</button>
                                            </div>
                                            <!-- End Payments -->



                                        </div>
                                    </form>
                                    <!-- End Card -->

                                    <!-- Form For kenchadze -->
                                    <Form v-else id="dk_form" class="card overflow-y-auto " @submit="registration">
                                        <div class="card-header">
                                            <div class="d-flex justify-content-between align-items-center w-100">
                                                <h6 class="m-0 d-flex align-items-center">
                                                    <span v-if="courses_by_id.demo_status === 1" class="d-block">პირველი
                                                        თვე</span>
                                                    <span v-else class="d-block">თვეში</span>

                                                </h6>
                                                <span class="fw-bold fs-3">{{ courses_by_id.price }} ₾</span>
                                            </div>
                                        </div>


                                        <div class="card-body">
                                            <h4 class="fs-4 fw-bold mb-3">რეგისტრაცია</h4>
                                            <!-- For Teenager and Hibrid -->
                                            <div class="row g-3"
                                                v-if="courses_by_id.type_registration.id === 'hibrid' || courses_by_id.type_registration.id === 'teenager'">
                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="name">მოსწავლის სახელი და გვარი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input" name="name"
                                                        id="name" :rules="name" v-model="form.name"
                                                        placeholder="სახელი და გვარი" />
                                                    <ErrorMessage class="text-danger error-msg" name="name"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div v-if="courses_by_id.demo_status !== 2" class="col-12">
                                                    <label for="email">მოსწავლის ელ.ფოსტა</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="email" class="form-control cast-input" name="email"
                                                        id="email" :rules="email" v-model="form.email"
                                                        placeholder="ელ.ფოსტა" />
                                                    <ErrorMessage class="text-danger error-msg" name="email"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="mobile">მოსწავლის ტელეფონის ნომერი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input" name="mobile"
                                                        id="mobile" :rules="mobile" v-model="form.mobile"
                                                        placeholder="მობილური" />
                                                    <ErrorMessage class="text-danger error-msg" name="mobile"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12"
                                                    v-if="courses_by_id.type_registration.id === 'teenager' && courses_by_id.demo_status !== 2">
                                                    <label for="age">მოზარდის ასაკი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" maxlength="2" class="form-control cast-input"
                                                        name="age" id="age" :rules="age" v-model="form.age"
                                                        placeholder="მოზარდის ასაკი" />
                                                    <ErrorMessage class="text-danger error-msg" name="age" as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12"
                                                    v-if="courses_by_id.type_registration.id === 'hibrid' && courses_by_id.demo_status !== 2">
                                                    <label>მოზარდის ასაკი</label>
                                                    <Field v-model="form.age_type" v-slot="{ Field }" :rules="age_type"
                                                        name="age_type">
                                                        <v-select :disabled="courses_by_id.status.id == 'unpublished'"
                                                            v-bind="Field" class="form-control cast-input"
                                                            name="age_type" placeholder="მოსწავლის ასაკი"
                                                            :reduce="(option) => option.id" label="text" :options="[
                                                                { text: '14-18 წლამდე', id: 'under' },
                                                                { text: '18 დან ზემოთ', id: 'not_under' }
                                                            ]" :rules="age_type" v-model="form.age_type"></v-select>
                                                    </Field>
                                                    <ErrorMessage class="text-danger error-msg" name="age_type"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12"
                                                    v-if="form.age_type === 'under' || courses_by_id.type_registration.id === 'teenager'">
                                                    <label for="parent_name">მშობლის სახელი და გვარი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input" name="parent_name"
                                                        id="parent_name" :rules="parent_name" v-model="form.parent_name"
                                                        placeholder="მშობლის სახელი და გვარი" />
                                                    <ErrorMessage class="text-danger error-msg" name="parent_name"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12"
                                                    v-if="form.age_type === 'under' || courses_by_id.type_registration.id === 'teenager'">
                                                    <label for="parent_email">მშობლის ელ.ფოსტა</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="email" class="form-control cast-input" name="parent_email"
                                                        id="parent_email" :rules="parent_email"
                                                        v-model="form.parent_email" placeholder="მშობლის ელ.ფოსტა" />
                                                    <ErrorMessage class="text-danger error-msg" name="parent_email"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12"
                                                    v-if="form.age_type === 'under' || courses_by_id.type_registration.id === 'teenager'">
                                                    <label for="parent_mobile">მშობლის ტელეფონის ნომერი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="email" class="form-control cast-input"
                                                        name="parent_mobile" id="parent_mobile" :rules="parent_mobile"
                                                        v-model="form.parent_mobile"
                                                        placeholder="მშობლის ტელეფონის ნომერი" />
                                                    <ErrorMessage class="text-danger error-msg" name="parent_mobile"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                            </div>
                                            <!-- End For Teenager and Hibrid -->

                                            <!-- For Company -->
                                            <div class="row g-3"
                                                v-if="courses_by_id.type_registration.id === 'company'">
                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="company_name">კომპანიის დასახელება</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input" name="company_name"
                                                        id="company_name" :rules="company_name"
                                                        v-model="form.company_name"
                                                        placeholder="კომპანიის დასახელება" />
                                                    <ErrorMessage class="text-danger error-msg" name="company_name"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="company_contact_name">საკონტაქტო პირის სახელი და
                                                        გვარი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input"
                                                        name="company_contact_name" id="company_contact_name"
                                                        :rules="company_contact_name"
                                                        v-model="form.company_contact_name"
                                                        placeholder="საკონტაქტო პირის სახელი და გვარი" />
                                                    <ErrorMessage class="text-danger error-msg"
                                                        name="company_contact_name" as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="company_email">საკონტაქტო პირის ელ.ფოსტა</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input" name="company_email"
                                                        id="company_email" :rules="company_email"
                                                        v-model="form.company_email"
                                                        placeholder="საკონტაქტო პირის ელ.ფოსტა" />
                                                    <ErrorMessage class="text-danger error-msg" name="company_email"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->

                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="company_mobile">საკონტაქტო პირის ტელეფონის
                                                        ნომერი</label>
                                                    <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                        type="text" class="form-control cast-input"
                                                        name="company_mobile" id="company_mobile"
                                                        :rules="company_mobile" v-model="form.company_mobile"
                                                        placeholder="საკონტაქტო პირის ტელეფონის ნომერი" />
                                                    <ErrorMessage class="text-danger error-msg" name="company_mobile"
                                                        as="small" />
                                                </div>
                                                <!-- End Col -->
                                            </div>
                                            <!-- End For Company -->

                                            <!-- Comment -->
                                            <div class="row mt-3">
                                                <!-- Col -->
                                                <div class="col-12">
                                                    <label for="comment">რატომ გსურს მოცემული კურსის გავლა? <small
                                                            class="text-muted" style="font-size: 10px;"
                                                            v-if="courses_by_id.demo_status === 2">(არასავალდებულო)</small></label>
                                                    <Field v-if="courses_by_id.demo_status !== 2"
                                                        :disabled="courses_by_id.status.id == 'unpublished'"
                                                        class="form-control cast-input" :rules="comment"
                                                        v-model="form.comment" name="comment" id="comment" rows="2"
                                                        as="textarea" />
                                                    <!-- For Front End Course -->
                                                    <Field v-if="courses_by_id.demo_status === 2"
                                                        :disabled="courses_by_id.status.id == 'unpublished'"
                                                        class="form-control cast-input" v-model="form.comment"
                                                        name="comment" id="comment" rows="2" as="textarea" />
                                                    <!-- End For Front End Course -->
                                                    <ErrorMessage v-if="courses_by_id.demo_status !== 2"
                                                        class="text-danger error-msg" name="comment" as="small" />
                                                </div>
                                                <!-- Col -->
                                            </div>
                                            <!-- End Comment -->

                                            <!-- Submit -->
                                            <div class="row mt-3">
                                                <!-- Submit -->
                                                <div class="col-12">
                                                    <!-- <button v-if="courses_by_id.demo_status === 1" type="button" class="btn btn-my-success mt-2 w-100 mb-1" @click="calendar()">შეხვდი მწვრთნელს <i class="bi bi-calendar2-check ms-2"></i></button> -->
                                                    <button :disabled="courses_by_id.status.id == 'unpublished'"
                                                        class="btn btn-my-primary w-100" type="submit"><span
                                                            v-if="courses_by_id.demo_status === 2">უფასო
                                                            კონსულტაცია</span> <span v-else>რეგისტრაცია</span> <i
                                                            class="bi bi-rocket"></i></button>
                                                </div>
                                                <!-- End Submit -->
                                            </div>
                                        </div>
                                    </Form>
                                    <!-- End Form For kenchadze -->
                                </div>
                                <!-- End Col -->
                            </div>
                        </div>
                    </div>
                    <!-- End Col Header Card -->
                </div>
                <!-- End Col Headed -->

                <!-- Col -->
                <div v-if="!isLoading" class="col-12">
                    <!-- Card -->
                    <div class="card border-0 b-white">
                        <div class="card-body">

                            <h5 class="head-title">{{ courses_by_id.name }}</h5>
                            <!-- <p>1 წლიანი კურსი </p> -->
                            <span class="badge bg-badge-success p-2 px-3 me-1"><i class="bi bi-laptop me-1"></i>
                                ონლაინ კურსი</span>
                            <!-- <span class="badge bg-badge-warning mb-2 mb-sm-5 p-2 px-3"><i class="bi bi-people me-1"></i> ფიზიკური შეხვედრები</span> -->


                        </div>



                        <div class="card-body">
                            <p v-html="courses_by_id.desc" class="card-text"></p>

                            <!-- Accordion -->
                            <h5 v-if="FAQ.length != 0" class="head-title mt-5">ხშირად დასმული კითხვები</h5>
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item" v-for="(item, index) in FAQ" :key="index">
                                    <h2 class="accordion-header" :id="'faq_' + item.id">
                                        <button class="accordion-button py-3 collapsed fw-bold" type="button"
                                            data-bs-toggle="collapse" :data-bs-target="'#faq_collapse_' + item.id"
                                            aria-expanded="false" :aria-controls="'faq_collapse_' + item.id">
                                            {{ item.name }}
                                        </button>
                                    </h2>
                                    <div :id="'faq_collapse_' + item.id" class="accordion-collapse collapse"
                                        :aria-labelledby="'faq_' + item.id" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p v-html="item.description"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Accordion -->
                        </div>

                        <div class="card-body p-0 p-md-3 my-5 my-md-3">
                            <swiper v-if="packages && packages.length > 0" :slides-per-view="3" :initialSlide="1"
                                :centeredSlides="false" :breakpoints="{
                                    320: {
                                        slidesPerView: 1.3,
                                        centeredSlides: true,
                                        initialSlide: 0,
                                        spaceBetween: 5
                                    },
                                    768: {
                                        slidesPerView: packages.length == 2 ? 2 : packages.length == 1 ? 2 : 4,
                                        spaceBetween: 10
                                    }
                                }">

                                <swiper-slide v-for="(item, index) in packages" :key="index">
                                    <div class="card package"
                                        :class="{ 'border border-3 border-success': item.name == 'Standart' }">
                                        <div class="card-body text-start">
                                            <h5
                                                class="card-title d-flex justify-content-between align-items-center mb-4">
                                                {{ item.name }}
                                                <small v-if="item.old_price"
                                                    class="text-decoration-line-through fs-4 text-danger">₾{{
                                                        item.old_price }}</small>
                                            </h5>
                                            <div class="d-flex align-items-center justify-content-center mb-5">
                                                <h6 class="amount fs-1">
                                                    <!-- <small class="text-decoration-line-through fs-4 text-muted">₾200</small> -->
                                                    <span class="text-success">₾{{ item.price }}</span>
                                                </h6>
                                                <small v-if="item.type.id == 'free'" class="price-description">
                                                    ერთიანი გადახდა
                                                </small>
                                                <small v-else class="price-description">
                                                    {{ item.type.text ? item.type.text : '' }}
                                                </small>
                                            </div>

                                            <button @click="package(item.package_id)" data-bs-toggle="modal"
                                                :data-bs-target="'#openPackageForm_' + item.price"
                                                class="btn btn-my-primary w-100 mb-5">სცადე <i
                                                    class="bi bi-rocket-takeoff"></i></button>

                                            <div class="divider border-bottom mb-4"></div>

                                            <ul class="features">
                                                <h1 v-if="item.includes" class="title">მოიცავს: {{ item.includes }}
                                                    <span v-if="item.name == 'Standart'">და Basic-ს</span>
                                                </h1>
                                                <li v-for="i in item.check_list" :key=i>
                                                    <span>
                                                        {{ i.name }}
                                                    </span>
                                                    <div class="icon">
                                                        <i class="bi bi-check-circle text-success"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>

                        </div>


                        <!-- Modal -->
                        <div v-for="item in packages" class="modal fade" :id="'openPackageForm_' + item.price"
                            :key="item" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                            aria-labelledby="openPackageFormLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="openPackageFormLabel">რეგისტრაცია</h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <!-- Card -->
                                        <form v-if="courses_by_id.registration_type == 'online'"
                                            class="card border-0 bg-light position-sticky top-300px">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between aling-items-center">
                                                    <h6 class="m-0 d-flex align-items-center">
                                                        <span v-if="courses_by_id.demo_status === 1"
                                                            class="d-block">პირველი თვე</span>

                                                        <span v-else class="d-block">
                                                            თვეში
                                                        </span>
                                                    </h6>
                                                    <!-- <span class="fw-bold fs-3">{{ courses_by_id.price }} ₾</span> -->
                                                    <span class="fw-bold fs-3">{{ item.price }} ₾</span>
                                                </div>
                                                <small
                                                    class="text-danger text-center d-block mt-2 border bg-white rounded p-2"
                                                    v-if="courses_by_id.demo_status === 1">სწავლის ღირებულება არის
                                                    პირველი თვის განმავლობაში 80 ლარი ხოლო მომდევნო თვიდან თვეში 300
                                                    ლარი.</small>


                                                <!-- Buy -->
                                                <div class="border-top mt-3 pt-4" v-if="!user">
                                                    <button type="button" class="btn btn-my-primary mt-4 w-100"
                                                        @click="calendar()">შეხვდი მწვრთნელს</button>

                                                    <button @click="buy()" type="button"
                                                        class="btn btn-my-success mt-2 w-100">კურსის შეძენა </button>
                                                    <div v-if="1 != 1"
                                                        class="d-flex justify-content-between align-items-center mt-3">
                                                        0% განვადება <img src="../../../assets/image/tbc.svg"
                                                            width="100">
                                                    </div>
                                                </div>
                                                <!-- End Buy -->

                                                <!-- Payments -->
                                                <div class="mt-3 pt-3 border-top" v-if="user">
                                                    <!-- Title -->
                                                    <h6>გადახდის მეთოდი</h6>
                                                    <!-- End Title -->

                                                    <!-- payment item -->
                                                    <div
                                                        class="d-flex justify-content-start align-items-center mt-2 mb-2 column-gap-1">
                                                        <input v-model="payment.pay" id="card" type="radio" name="cards"
                                                            value="with_card">
                                                        <label
                                                            class="d-flex justify-content-between align-items-center w-100"
                                                            for="card">
                                                            <p class="card-text mb-0">ონლაინ გადახდა</p>
                                                            <div class="d-flex align-items-center column-gap-1">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                    height="30" viewBox="0 0 80 80">
                                                                    <g transform="translate(0 0.446)">
                                                                        <g id="Group_2497" data-name="Group 2497"
                                                                            transform="translate(2.439 16.745)">
                                                                            <path id="Path_3314" data-name="Path 3314"
                                                                                d="M1930.809,978.125v-.905h-.234l-.283.62-.282-.62h-.235v.905h.166v-.679l.255.588h.173l.254-.588v.682h.167Zm-1.493,0v-.75h.3v-.153h-.77v.153h.3v.75Z"
                                                                                transform="translate(-1856.211 -940.415)"
                                                                                fill="#f79410"></path>
                                                                            <path id="Path_3315" data-name="Path 3315"
                                                                                d="M750.079,168.758H729.711V132.15h20.368Z"
                                                                                transform="translate(-702.224 -127.172)"
                                                                                fill="#ff5f00"></path>
                                                                            <path id="Path_3316" data-name="Path 3316"
                                                                                d="M28.776,23.28a23.244,23.244,0,0,1,8.891-18.3,23.281,23.281,0,1,0,0,36.608,23.242,23.242,0,0,1-8.891-18.3"
                                                                                transform="translate(0.001 0.001)"
                                                                                fill="#eb001b"></path>
                                                                            <path id="Path_3317" data-name="Path 3317"
                                                                                d="M1037.771,23.281a23.281,23.281,0,0,1-37.666,18.3,23.284,23.284,0,0,0,0-36.608,23.282,23.282,0,0,1,37.666,18.3"
                                                                                transform="translate(-962.451 0.002)"
                                                                                fill="#f79e1b"></path>
                                                                        </g>
                                                                        <rect id="Rectangle_996"
                                                                            data-name="Rectangle 996" width="80"
                                                                            height="80" transform="translate(0 -0.446)"
                                                                            fill="none"></rect>
                                                                    </g>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                    height="30" viewBox="0 0 80 80">
                                                                    <g id="Group_2494" data-name="Group 2494"
                                                                        transform="translate(1.914 26.816)">
                                                                        <g id="Group_2493" data-name="Group 2493"
                                                                            transform="translate(0 0)">
                                                                            <path id="Path_3305" data-name="Path 3305"
                                                                                d="M44.15,75.748h-6.2l3.872-23.8h6.2Zm-11.41-23.8L26.832,68.316l-.7-3.525h0l-2.086-10.7a2.653,2.653,0,0,0-2.94-2.144H11.341l-.115.4a23.183,23.183,0,0,1,6.483,2.721l5.383,20.68h6.457l9.859-23.8Zm48.743,23.8h5.691l-4.961-23.8H77.23a2.847,2.847,0,0,0-2.862,1.774l-9.244,22.03h6.461l1.292-3.535h7.877Zm-6.818-8.42,3.255-8.908,1.833,8.908Zm-9.043-9.66.884-5.112a17.885,17.885,0,0,0-5.574-1.037c-3.075,0-10.379,1.344-10.379,7.879,0,6.149,8.571,6.229,8.571,9.457s-7.688,2.651-10.226.615l-.92,5.345a17.367,17.367,0,0,0,7,1.344c4.22,0,10.608-2.19,10.608-8.149,0-6.189-8.641-6.766-8.641-9.457s6.029-2.345,8.687-.884Z"
                                                                                transform="translate(-11.226 -51.519)"
                                                                                fill="#2566af"></path>
                                                                        </g>
                                                                        <path id="Path_3306" data-name="Path 3306"
                                                                            d="M26.2,65.106l-2.1-10.756A2.665,2.665,0,0,0,21.154,52.2H11.341l-.115.4a24.128,24.128,0,0,1,9.242,4.644A18.651,18.651,0,0,1,26.2,65.106Z"
                                                                            transform="translate(-11.226 -51.772)"
                                                                            fill="#e6a540"></path>
                                                                    </g>
                                                                    <rect id="Rectangle_994" data-name="Rectangle 994"
                                                                        width="80" height="80" fill="none"></rect>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30"
                                                                    height="30" viewBox="0 0 80 80">
                                                                    <g transform="translate(0 -0.277)">
                                                                        <g id="Group_2495" data-name="Group 2495"
                                                                            transform="translate(1.954 12.71)">
                                                                            <path id="Path_3307" data-name="Path 3307"
                                                                                d="M4.754,41.025H71.3a4.754,4.754,0,0,1,4.754,4.754V90.94A4.754,4.754,0,0,1,71.3,95.694H4.754A4.754,4.754,0,0,1,0,90.94V45.779a4.754,4.754,0,0,1,4.754-4.754Z"
                                                                                transform="translate(0 -41.025)"
                                                                                fill="#26a6d1"></path>
                                                                            <path id="Path_3308" data-name="Path 3308"
                                                                                d="M25.769,113.966l-7.534,16.268h9.017l1.119-2.593h2.557l1.119,2.593h9.928v-1.981l.885,1.981H48l.885-2.022v2.022H69.518l2.511-2.539,2.352,2.539,10.6.022-7.557-8.11,7.557-8.18H74.547L72.1,116.445l-2.277-2.479H47.382l-1.929,4.2-1.975-4.2H34.464v1.9l-1-1.9Zm1.744,2.311h4.395l5,11.03v-11.03h4.814l3.858,7.91,3.559-7.91h4.792v11.675H51l-.023-9.148-4.229,9.145h-2.6L39.87,118.8v9.148H33.859l-1.139-2.616H26.579l-1.135,2.616H22.23Zm29.085,0H68.454l3.627,3.809,3.744-3.809h3.627l-5.511,5.866,5.511,5.8H75.66l-3.627-3.866-3.764,3.866H56.6V116.277ZM29.65,118.251l-2.023,4.663h4.047Zm29.875.441v2.13h6.467V123.2H59.528v2.327h7.254l3.37-3.428-3.227-3.409h-7.4Z"
                                                                                transform="translate(-13.582 -94.775)"
                                                                                fill="#fff"></path>
                                                                        </g>
                                                                        <rect id="Rectangle_995"
                                                                            data-name="Rectangle 995" width="80"
                                                                            height="80" transform="translate(0 0.277)"
                                                                            fill="none"></rect>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <select disabled
                                                        class="w-100 border-secondary rounded cursor-pointer"
                                                        style="border: 1px solid #dfdfdf!important;padding: 10px;border-radius: 13px!important;height:43px;"
                                                        v-if="payment.pay === 'with_card'"
                                                        v-model="payment.subscribtion">
                                                        <option selected value="monthly">თვეში</option>
                                                    </select>
                                                    <!-- End payment item -->

                                                    <!-- payment item -->
                                                    <div v-if="1 != 1"
                                                        class="d-flex justify-content-start align-items-center mt-3 column-gap-1">
                                                        <input v-model="payment.pay" id="bank" type="radio" name="cards"
                                                            value="with_bank">
                                                        <label
                                                            class="d-flex justify-content-between align-items-center w-100"
                                                            for="bank">
                                                            <p class="card-text mb-0">თიბისი განვადება</p>
                                                            <img src="../../../assets/image/tbc.svg" width="100">
                                                        </label>
                                                    </div>
                                                    <!-- End payment item -->
                                                    <button type="button" class="btn btn-my-primary mt-4 w-100"
                                                        @click="calendar()">შეხვდი მწვრთნელს <i
                                                            class="bi bi-calendar2-check ms-2"></i></button>
                                                    <div class="alert alert-danger text-center mt-4"
                                                        v-if="cart_error == 'error_cart'">თქვენ უკვე სარგებლობთ მოცემული
                                                        მომსახურეობით.</div>
                                                    <button :disabled="payment.pay !== 'with_card'" @click="add();"
                                                        type="button" class="btn btn-my-success w-100 mt-3">ონლაინ
                                                        გადახდა <i class="bi bi-rocket"></i></button>
                                                    <button v-if="1 != 1" :disabled="payment.pay !== 'with_bank'"
                                                        class="btn btn-my-primary w-100 mt-1">განვადება</button>
                                                </div>
                                                <!-- End Payments -->



                                            </div>
                                        </form>
                                        <!-- End Card -->

                                        <!-- Form For kenchadze -->
                                        <Form v-else id="dk_form" class="card overflow-y-auto " @submit="registration">
                                            <div class="card-header">
                                                <div class="d-flex justify-content-between align-items-center w-100">
                                                    <h6 class="m-0 d-flex align-items-center">
                                                        <span v-if="courses_by_id.demo_status === 1"
                                                            class="d-block">პირველი თვე</span>
                                                        <span v-else class="d-block">თვეში</span>

                                                    </h6>
                                                    <!-- <span class="fw-bold fs-3">{{ courses_by_id.price }} ₾</span> -->
                                                    <span class="fw-bold fs-3">{{ item.price }} ₾</span>
                                                </div>
                                            </div>


                                            <div class="card-body">
                                                <!-- <h4 class="fs-4 fw-bold mb-3">რეგისტრაცია</h4> -->
                                                <!-- For Teenager and Hibrid -->
                                                <div class="row g-3"
                                                    v-if="courses_by_id.type_registration.id === 'hibrid' || courses_by_id.type_registration.id === 'teenager'">
                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="name"><!--მოსწავლის--> სახელი და გვარი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input" name="name"
                                                            id="name" :rules="name" v-model="form.name"
                                                            placeholder="სახელი და გვარი" />
                                                        <ErrorMessage class="text-danger error-msg" name="name"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div v-if="courses_by_id.demo_status !== 2" class="col-12">
                                                        <label for="email">მოსწავლის ელ.ფოსტა</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="email" class="form-control cast-input" name="email"
                                                            id="email" :rules="email" v-model="form.email"
                                                            placeholder="ელ.ფოსტა" />
                                                        <ErrorMessage class="text-danger error-msg" name="email"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="mobile"><!-- მოსწავლის --> ტელეფონის ნომერი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input" name="mobile"
                                                            id="mobile" :rules="mobile" v-model="form.mobile"
                                                            placeholder="მობილური" />
                                                        <ErrorMessage class="text-danger error-msg" name="mobile"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12"
                                                        v-if="courses_by_id.type_registration.id === 'teenager' && courses_by_id.demo_status !== 2">
                                                        <label for="age">მოზარდის ასაკი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" maxlength="2" class="form-control cast-input"
                                                            name="age" id="age" :rules="age" v-model="form.age"
                                                            placeholder="მოზარდის ასაკი" />
                                                        <ErrorMessage class="text-danger error-msg" name="age"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12"
                                                        v-if="courses_by_id.type_registration.id === 'hibrid' && courses_by_id.demo_status !== 2">
                                                        <label>მოზარდის ასაკი</label>
                                                        <Field v-model="form.age_type" v-slot="{ Field }"
                                                            :rules="age_type" name="age_type">
                                                            <v-select
                                                                :disabled="courses_by_id.status.id == 'unpublished'"
                                                                v-bind="Field" class="form-control cast-input"
                                                                name="age_type" placeholder="მოსწავლის ასაკი"
                                                                :reduce="(option) => option.id" label="text" :options="[
                                                                    { text: '14-18 წლამდე', id: 'under' },
                                                                    { text: '18 დან ზემოთ', id: 'not_under' }
                                                                ]" :rules="age_type"
                                                                v-model="form.age_type"></v-select>
                                                        </Field>
                                                        <ErrorMessage class="text-danger error-msg" name="age_type"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12"
                                                        v-if="form.age_type === 'under' || courses_by_id.type_registration.id === 'teenager'">
                                                        <label for="parent_name">მშობლის სახელი და გვარი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input"
                                                            name="parent_name" id="parent_name" :rules="parent_name"
                                                            v-model="form.parent_name"
                                                            placeholder="მშობლის სახელი და გვარი" />
                                                        <ErrorMessage class="text-danger error-msg" name="parent_name"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12"
                                                        v-if="form.age_type === 'under' || courses_by_id.type_registration.id === 'teenager'">
                                                        <label for="parent_email">მშობლის ელ.ფოსტა</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="email" class="form-control cast-input"
                                                            name="parent_email" id="parent_email" :rules="parent_email"
                                                            v-model="form.parent_email"
                                                            placeholder="მშობლის ელ.ფოსტა" />
                                                        <ErrorMessage class="text-danger error-msg" name="parent_email"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12"
                                                        v-if="form.age_type === 'under' || courses_by_id.type_registration.id === 'teenager'">
                                                        <label for="parent_mobile">მშობლის ტელეფონის ნომერი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="email" class="form-control cast-input"
                                                            name="parent_mobile" id="parent_mobile"
                                                            :rules="parent_mobile" v-model="form.parent_mobile"
                                                            placeholder="მშობლის ტელეფონის ნომერი" />
                                                        <ErrorMessage class="text-danger error-msg" name="parent_mobile"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                </div>
                                                <!-- End For Teenager and Hibrid -->

                                                <!-- For Company -->
                                                <div class="row g-3"
                                                    v-if="courses_by_id.type_registration.id === 'company'">
                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="company_name">კომპანიის დასახელება</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input"
                                                            name="company_name" id="company_name" :rules="company_name"
                                                            v-model="form.company_name"
                                                            placeholder="კომპანიის დასახელება" />
                                                        <ErrorMessage class="text-danger error-msg" name="company_name"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="company_contact_name">საკონტაქტო პირის სახელი და
                                                            გვარი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input"
                                                            name="company_contact_name" id="company_contact_name"
                                                            :rules="company_contact_name"
                                                            v-model="form.company_contact_name"
                                                            placeholder="საკონტაქტო პირის სახელი და გვარი" />
                                                        <ErrorMessage class="text-danger error-msg"
                                                            name="company_contact_name" as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="company_email">საკონტაქტო პირის ელ.ფოსტა</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input"
                                                            name="company_email" id="company_email"
                                                            :rules="company_email" v-model="form.company_email"
                                                            placeholder="საკონტაქტო პირის ელ.ფოსტა" />
                                                        <ErrorMessage class="text-danger error-msg" name="company_email"
                                                            as="small" />
                                                    </div>
                                                    <!-- End Col -->

                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="company_mobile">საკონტაქტო პირის ტელეფონის
                                                            ნომერი</label>
                                                        <Field :disabled="courses_by_id.status.id == 'unpublished'"
                                                            type="text" class="form-control cast-input"
                                                            name="company_mobile" id="company_mobile"
                                                            :rules="company_mobile" v-model="form.company_mobile"
                                                            placeholder="საკონტაქტო პირის ტელეფონის ნომერი" />
                                                        <ErrorMessage class="text-danger error-msg"
                                                            name="company_mobile" as="small" />
                                                    </div>
                                                    <!-- End Col -->
                                                </div>
                                                <!-- End For Company -->

                                                <!-- Comment -->
                                                <div class="row mt-3">
                                                    <!-- Col -->
                                                    <div class="col-12">
                                                        <label for="comment">რატომ გსურს მოცემული კურსის გავლა? <small
                                                                class="text-muted" style="font-size: 10px;"
                                                                v-if="courses_by_id.demo_status === 2">(არასავალდებულო)</small></label>
                                                        <Field v-if="courses_by_id.demo_status !== 2"
                                                            :disabled="courses_by_id.status.id == 'unpublished'"
                                                            class="form-control cast-input" :rules="comment"
                                                            v-model="form.comment" name="comment" id="comment" rows="2"
                                                            as="textarea" />
                                                        <!-- For Front End Course -->
                                                        <Field v-if="courses_by_id.demo_status === 2"
                                                            :disabled="courses_by_id.status.id == 'unpublished'"
                                                            class="form-control cast-input" v-model="form.comment"
                                                            name="comment" id="comment" rows="2" as="textarea" />
                                                        <!-- End For Front End Course -->
                                                        <ErrorMessage v-if="courses_by_id.demo_status !== 2"
                                                            class="text-danger error-msg" name="comment" as="small" />
                                                    </div>
                                                    <!-- Col -->
                                                </div>
                                                <!-- End Comment -->

                                                <!-- Submit -->
                                                <div class="row mt-3">
                                                    <!-- Submit -->
                                                    <div class="col-12">
                                                        <!-- <button v-if="courses_by_id.demo_status === 1" type="button" class="btn btn-my-success mt-2 w-100 mb-1" @click="calendar()">შეხვდი მწვრთნელს <i class="bi bi-calendar2-check ms-2"></i></button> -->
                                                        <button :disabled="courses_by_id.status.id == 'unpublished'"
                                                            class="btn btn-my-primary w-100" type="submit"><span
                                                                v-if="courses_by_id.demo_status === 2">უფასო
                                                                კონსულტაცია</span> <span v-else>რეგისტრაცია</span> <i
                                                                class="bi bi-rocket"></i></button>
                                                    </div>
                                                    <!-- End Submit -->
                                                </div>
                                            </div>
                                        </Form>
                                        <!-- End Form For kenchadze -->
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light"
                                            data-bs-dismiss="modal">დახურვა</button>
                                        <!-- <button type="button" class="btn btn-primary">გაგ</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- End Card -->
                </div>
                <!-- End Col -->
            </div>
            <!-- End Row -->

            <!-- what our students say -->
            <div v-if="recomendation_list.length !== 0" class="row g-3 mt-5">
                <!-- Col -->
                <div class="col-md-12 mb-4">
                    <h5 class="head-title">რას ამბობენ ჩვენი სტუდენტები? </h5>
                </div>
                <!-- End Col -->

                <div v-for="(item, index) in recomendation_list" :key="index" class="col-12">
                    <div class="card shadow-sm border-0 shadow-sm border-0 rounded-4">
                        <div class="card-body">
                            <div class="d-flex align-items-center gap-3 mb-3">
                                <div class="rounded-circle bg-custom-primary text-white d-flex align-items-center justify-content-center w-100"
                                    style="max-width: 50px;height:50px;"><i class="bi bi-rocket-takeoff"></i></div>
                                <h6 class="m-0">{{ item.name }}</h6>
                            </div>
                            <p class="text-start" v-html="item.short_desc"></p>
                            <div class="d-flex column-gap-1">
                                <a v-if="item.facebook" :href="item.facebook"><i class="bi bi-facebook"></i></a>
                                <a v-if="item.instagram" :href="item.instagram"><i class="bi bi-instagram"></i></a>
                                <a v-if="item.linkedin" :href="item.linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Col Swiper -->
                <!-- <div v-if="1 != 1" class="col-12">
                    <swiper
                        :slidesPerView="3"
                        :spaceBetween="30"
                        :autoplay="{ delay: 3000 }"
                        :pagination="{
                            clickable: true
                        }"
                        :navigation="{
                            enabled: true
                        }"
                        :breakpoints="   {    
                            320: {       
                                slidesPerView: 1  
                            },          
                            414: {       
                                slidesPerView: 1,    
                            },   
                            768: {       
                                slidesPerView: 1,    
                            },
                            1200: {       
                                slidesPerView: 1,    
                            }
                        }"
                        :modules="modules"
                        class="mySwiper"
                    >
                        <swiper-slide class="bg-transparent align-items-start" v-for="(item, index) in recomendation_list">
                            <div class="card shadow-sm border-0 shadow-sm border-0 rounded-4">
                                <div class="card-body">
                                    <div class="d-flex align-items-center gap-3 mb-3">
                                        <div class="rounded-circle bg-custom-primary text-white d-flex align-items-center justify-content-center w-100" style="max-width: 50px;height:50px;"><i class="bi bi-rocket"></i></div>
                                        <h6 class="m-0">{{ item.name }}</h6>
                                    </div>
                                    <p class="text-start" v-html="item.short_desc"></p>
                                </div>
                            </div>
                        </swiper-slide>

                    </swiper>
                </div> -->
                <!-- End Col Swiper -->
            </div>
            <!-- End what our students say -->

        </div>
        <!-- End Course Inner -->

        <!-- Registration Successs -->
        <div class="modal fade" id="registrationSuccess" data-bs-backdrop="static" data-bs-keyboard="false"
            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">სტატუსი</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body rounded-bottom p-0 position-relative">
                        <div v-if="checkerror"
                            class="alert alert-danger d-flex flex-column justify-content-center align-items-center text-center gap-3 rounded-0 rounded-bottom 0 m-0 border-0 mh-100 pb-5"
                            role="alert">
                            <img class="w-25" src="@/assets/image/checkerror.png">
                            რეგისტრაცია ვერ მოხერხდა
                        </div>
                        <div v-if="checkRegistration"
                            class="alert alert-success d-flex flex-column justify-content-center align-items-center text-center gap-3 rounded-0 rounded-bottom 0 m-0 border-0 mh-100 pb-5"
                            role="alert">
                            <img class="check_Status" src="@/assets/image/checksuccess.png">
                            თქვენ წარმატებით გაიარეთ რეგისტრაცია
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import vSelect from "vue-select";

// axios
import axios from 'axios';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// import required modules
// import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export default {
    name: "Course",
    data() {
        return {
            user: false,
            audio_1: false,
            cart_error: '',
            payment: {
                pay: 'with_card',
                subscribtion: 'monthly'
            },
            form: {
                name: '',
                mobile: '',
                email: '',
                parent_name: '',
                parent_mobile: '',
                parent_email: '',
                age_type: '',
                age: '',

                company_name: '',
                company_contact_name: '',
                company_email: '',
                company_mobile: '',

                comment: '',

                package_id: '',
            },
            checkRegistration: false,
            checkerror: false,
            FAQ: [],
            courses_by_id: {
                type_registration: {},
                status: {
                    id: ''
                },
                payment_type: {
                    text: ''
                }
            },
            recomendation_list: [],
            no_payment_registration: {
                type_registration: {},
                status: {
                    id: ''
                },
                payment_type: {
                    text: ''
                }
            },
            isLoading: true,
            // Packages
            packages: []
        }

    },
    components: {
        Swiper,
        SwiperSlide,
        Form, Field, ErrorMessage,
        vSelect
    },
    methods: {
        registration() {
            // call success modal
            const buttonElement = document.createElement('button');
            buttonElement.classList.add('d-none');
            buttonElement.setAttribute('data-bs-toggle', 'modal');
            buttonElement.setAttribute('data-bs-target', '#registrationSuccess');
            document.body.appendChild(buttonElement);
            const element = document.querySelector('[data-bs-target="#registrationSuccess"]');
            if (element) {
                element.click();
            }
            buttonElement.remove();

            const apiUrl = 'https://recruting.dkcapital.ge/api';
            axios.post(apiUrl + '/public/traning/signup/' + this.$route.params.id, this.form)
                .then(res => {
                    this.checkRegistration = true;
                    this.form = {
                        name: '',
                        mobile: '',
                        email: '',
                        parent_name: '',
                        parent_mobile: '',
                        parent_email: '',
                        age_type: '',
                        age: '',

                        company_name: '',
                        company_contact_name: '',
                        company_email: '',
                        company_mobile: '',

                        comment: ''
                    }
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000)
                }
                ).catch(error => {
                    this.checkerror = true;
                })
        },
        // Fill form.price variable with current package price
        package(id) {
            this.form.package_id = id;
        },
        categoryData() {
            // const apiUrl = process.env.API_MOTIVATION_URL;
            axios.get('https://recruting.dkcapital.ge/api/public/traning/get_by_id/' + this.$route.params.id)
                .then((res) => {
                    this.courses_by_id = res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        get_packages() {
            this.isLoading = true;
            axios.get(process.env.VUE_APP_API_MOTIVATION_URL + '/public/packages/getList/' + this.$route.params.id)
                .then((res) => {
                    this.isLoading = false;
                    this.packages = res.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },

        getCourse_id() {
            this.isLoading = true;
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/traning/get_by_id/' + this.$route.params.id)
                .then((res) => {
                    this.isLoading = false;
                    this.courses_by_id = res.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        getFAQ() {

            // const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.get('https://recruting.dkcapital.ge/api/auth/Faq/GetList/' + this.$route.params.id
                // headers: {
                //     'Authorization': `Bearer ${token}`
                // }
                // })
            )
                .then(response => {
                    this.FAQ = response.data;
                });
        },
        recomendations() {
            axios.get('https://recruting.dkcapital.ge/api/public/recomendation/getList/' + this.$route.params.id)
                .then((res) => {
                    this.recomendation_list = res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        play() {
            this.audio_1 = true;
            // this.audio_2 = false;
        },
        stop() {
            this.audio_1 = false;
            // this.audio_2 = true;
            // setTimeout(() => {
            //     this.audio_2 = false
            // }, 2000)
        },
        calendar() {
            Calendly.showPopupWidget('https://calendly.com/g-katsarava/test');
            return false;
        },
        buy() {
            if (this.$route.params.id) {
                this.$router.push({
                    path: '/Signup'
                })
                localStorage.setItem('course_id', this.$route.params.id);
            }
        },



        // Fields Validations
        name(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითეთ სახელი და გვარი';
            }

            return true;
        },
        email(value) {
            // if the field is empty
            if (!value) {
                return 'შეიყვანეთ ელ.ფოსტა';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
            }
            // All is good
            return true;
        },
        mobile(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე ტელეფონის ნომერი';
            }

            return true;
        },
        age(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე მოზარდის ასაკი';
            }

            return true;
        },
        age_type(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე მოსწავლის ასაკი';
            }

            return true;
        },
        parent_name(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე მშობლის სახელი და გვარი';
            }

            return true;
        },
        parent_email(value) {
            // validate parent email

            // if the field is empty
            if (!value) {
                return 'მიუთითე მშობლის ელ.ფოსტა';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
            }
            // All is good
            return true;

        },
        parent_mobile(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე მშობლის ტელეფონის ნომერი';
            }

            return true;
        },

        company_name(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე კომპანიის დასახელება';
            }

            return true;
        },
        company_contact_name(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე საკონტაქტო პირის სახელი და გვარი';
            }

            return true;
        },
        company_email(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე საკონტაქტო პირის ელ.ფოსტა';
            }

            return true;
        },
        company_mobile(value) {
            // validate name value...
            if (!value) {
                return 'მიუთითე საკონტაქტო პირის ტელეფონის ნომერი';
            }

            return true;
        },

        comment(value) {
            // validate name value...
            if (!value) {
                return 'აუცილებელი ველი';
            }

            return true;
        },
        add() {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Cart/add', {
                'traning_id': this.$route.params.id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    this.$router.push('/Dashboard');
                })
                .catch((error) => {
                    this.cart_error = 'error_cart';
                });
            console.log(this.$route.params.id);
        }
    },
    mounted() {

        this.user = localStorage.getItem('user');

        // for animation
        const btn = document.querySelectorAll('.btn-my-primary');
        btn.forEach(e => {
            e.addEventListener('mouseenter', this.play);
            e.addEventListener('mouseleave', this.stop);
        })

        // scroll to the top
        window.scrollTo(0, 0);

        // get data of courses
        this.getCourse_id();

        this.recomendations();
        // this.categoryData();
        this.getFAQ();


        if (this.user) {
            this.form.name = JSON.parse(this.user).user.name;
            this.form.mobile = JSON.parse(this.user).user.mobile;
            this.form.email = JSON.parse(this.user).user.email;
        }


        this.get_packages();

    },
    updated() {
        let audio = document.querySelectorAll("audio");
        audio.forEach(e => {
            e.volume = 0.05
        })



    },
    watch: {
        $refs: {
            handler(newRefs) {
                if (newRefs.swiper) {
                    // If swiper reference is available, update swiper
                    newRefs.swiper.update();
                }
            },
            deep: true, // Watch changes deeply
        },
    },

    setup() {
        return {
            // modules: [Pagination, Navigation, Autoplay],
        };

    }
}
</script>


<style scoped>
.course-image {
    border-radius: 12px;
}

.course-image img {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    border-radius: 12px;
}

.banks {
    width: 30px;
}

.card {
    border-radius: 12px !important;
}

.badge {
    font-size: 15px;

    -moz-font-feature-settings: 'case';
    -webkit-font-feature-settings: 'case';
    font-feature-settings: 'case' on;

    font-family: var(--second-font) !important;
}

.auth {
    max-width: 500px;
    margin-inline: auto;

    background-color: #fff;
    padding: 30px;
}

.cast-input {
    background-color: #f8f5f5;
}

.package .amount {
    margin: 0;
    margin-right: 8px;
}

.package .price-description {
    line-height: 18px;
    font-size: 0.8125rem;
    color: #323338;
    text-align: start;
}

.package .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px
}

.package .features {
    list-style: none;
    margin: 0;
    padding: 0;
}

.package .features li {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 24px;
}

.package .features li span {
    font-size: 0.844rem;
    color: #333;
}


.video_text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    z-index: 1;
}

@media screen and (max-width:768px) {
    .video_text {
        font-size: 20px;
        width: 90%;
    }
}
</style>