import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';

import Home from '../views/Home.vue'
//* auth ========================================= *//
import Signin from '../views/auth/Signin.vue'
import Signup from '../views/auth/Signup.vue'
import Recover from '../views/auth/Recover.vue'
import Reset from '../views/auth/Reset.vue'
// Dashboard Pages of auth
import Dashboard from '../views/auth/Dashboard/Index.vue'


//* public ========================================= *//
// Course Routes
import All_Course from '../views/public/Courses/Index.vue'
import Course from '../views/public/Course/Index.vue'
import Success from '../views/public/Course/success.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  //* auth ========================================= *//
  // authorization of auth
  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/Recover',
    name: 'Recover',
    component: Recover
  },
  {
    path: '/Reset',
    name: 'Reset',
    component: Reset
  },
  // Dashboard Pages of auth
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },



  //* public ========================================= *//

  // Course Routes of public
  {
    path: '/Course/:id',
    name: 'Course',
    component: Course
  },
  {
    path: '/All_Course',
    name: 'All_Course',
    component: All_Course
  },
  {
    path: '/Success',
    name: 'Success',
    component: Success
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {


//   if (from.params && to.params.id) {
//     // Find the og:image meta tag
//     const metaImage = document.querySelector('meta[property="og:image"]')

//     // Make an Axios request to fetch the training's image URL
//     try {
//         // Replace 'YOUR_TRAINING_ID' with the actual ID or parameter you use to identify the training
//         const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/traning/get_by_id/' + to.params.id)

//         // Assuming the response data contains the image URL, update the og:image content
//         if (metaImage) {
//             metaImage.setAttribute('content', response.data.file)
//         }
//     } catch (error) {
//         console.error('Error fetching training image:', error)
//     }
// }

//  // Find the meta tag for og:title
//  const metaTitle = document.querySelector('meta[property="og:title"]')

//  // Set the content attribute of the meta tag based on the route parameters
//  if (metaTitle) {
//    metaTitle.setAttribute('content', to.params.name || 'David Kenchadze - გახდი შენი თავის საუკეთესო ვერსია')
//  }

 // Set the document title based on the route parameters
 document.title = to.params.name || 'David Kenchadze - გახდი შენი თავის საუკეთესო ვერსია'

 // Continue with the navigation
 next()

  if(to.name == 'Signin' || to.name == 'Signup' || to.name == 'Reset' || to.name == 'Recover') {
    if(localStorage.getItem('user')) {
      router.push({ 
        path: "/"
      })
    }
  }
  if(to.name == 'Dashboard' || to.name == 'Cart') {
    if(!localStorage.getItem('user')) {
      router.push({ 
        path: "/"
      })
    }
  }
})

export default router