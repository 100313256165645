<template>
  <div>
    <!-- Footer -->
    <footer class="footer py-5">
        <!-- Container -->
        <div class="container">
            <!-- Row -->
            <div class="row g-5">
                <div class="col-md-12 col-xl-4">
                    <!-- Logo -->
                    <a class="logo d-flex justify-content-center d-md-block position-relative p-0" href="/">
                        <img style="width: 200px;" src="../assets/image/footerWhiteLogo.svg" alt="logo">

                    </a>
                    <!-- Logo End -->
                </div>
                <div class="col-md-6 col-xl-2">
                    <h5>სწრაფი ლინკები</h5>
                    <ul class="list-unstyled">
                        <li>
                            <a href="#programs">პროგრამები</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://api.crm.reachstar.io/rules/rules-reachstar.pdf">წესები და პირობები</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 col-xl-2">
                    <h5>პროგრამები</h5>
                    <ul class="list-unstyled">
                        <li v-for="(item, index) in courses" :key="index">
                            <a :href="'/Course/'+item.id">{{ item.name }} </a>
                        </li>
                    </ul>
                </div>
                <div id="Footer" class="col-md-6 col-xl-2">
                    <h5>KENCHADZE.GROUP</h5>
                    <ul class="list-unstyled">
                        <li>
                            <a target="_blank" href="https://dk.ge/Home">ვაკანსიები</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://academy.dk.ge/">აკადემია</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://re.dk.ge/">უძრავი ქონება</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://re.dk.ge/Investments">ინვესტიციები</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://davidkenchadze.ge/">სამოტივაციო ბლოგი</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6 col-xl-2">
                    <h5>კონტაქტი</h5>
                    <ul class="list-unstyled">
                        <li>
                            <a href="tel:568951554">568 951 554</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- End Row -->
        </div>
        <!-- End Container -->
    </footer>
    <!-- End Footer -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Footer",
    data() {
        return {
            courses: []
        }
    },
    methods: {
        getCourses() {
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/traning/LoadData')
                .then((res) => {
                    this.courses = res.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // dynamicRoute(name, id) {

        //     // Replace "%20" with underscores
        //     var item_name = name.replace(/ /g, "-");

        //     this.$router.push({
        //         path: `/Course/${item_name}/${id}`
        //     })
        // }
    },
    mounted() {
        this.getCourses();
    }
}
</script>

<style scoped>
.list-unstyled {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 0;
    margin-top: 20px;
}
.logo {
    padding-left: 7px;
    padding-top: 7px;
}
.logo_circle {
    background-color: var(--primary-color);
    width: 39px;
    height: 39px;
    border-radius: 50%;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: -1;
}
</style>