<template>
   <div class="my-5 p-3 p-sm-0">
        <div class="background-animation"></div>
        <div class="loader" v-if="isLoading">
          <img src="@/assets/loader.gif">
        </div>

        <!-- Singnin -->
        <div class="auth">
            <Form class="row g-3" @submit="submitForm" :validation-schema="simpleSchema">

                    <!-- Col -->
                    <div class="col-12">
                        <router-link to="/" class="logo d-block position-relative">
                            <!-- <div class="logo_circle placeholder-wave"></div> -->
     
                            <img style="width: 200px;" src="@/assets/image/dklogo.png" alt="logo">

                        </router-link>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12 mt-5">
                        <h4 class="fs-4">რეგისტრაცია</h4>
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12">
                        <label for="name" class="form-label">სახელი და გვარი</label>
                        <Field v-model="form.name" type="text" name="name" class="form-control cast-input" id="name" placeholder="სახელი" />
                        <ErrorMessage class="text-danger" name="name" style="font-size: 14px;" />
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <!-- <div class="col-12">
                        <label for="personal_id" class="form-label">პირადი ნომერი</label>
                        <Field v-model="form.personal_id" type="text" maxlength="11" name="personal_id" class="form-control cast-input" id="personal_id" placeholder="xxxxxxxxxxx" />
                        <ErrorMessage class="text-danger" name="personal_id" style="font-size: 14px;" />
                    </div> -->
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12">
                        <label for="mobile" class="form-label">საკონტაქტო ტელეფონი</label>
                        <Field v-model="form.mobile" type="text" name="mobile" class="form-control cast-input" id="mobile" placeholder="xxx xx xx xx" />
                        <ErrorMessage class="text-danger" name="mobile" style="font-size: 14px;" />
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12">
                        <label for="email" class="form-label">ელ.ფოსტა</label>
                        <Field v-model="form.email" type="email" name="email" class="form-control cast-input" id="email" placeholder="ელ.ფოსტა" />
                        <ErrorMessage class="text-danger" name="email" style="font-size: 14px;" />
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12">
                        <label for="password" class="form-label">პაროლი</label>
                        <Field v-model="form.password" type="password" name="password" class="form-control cast-input" id="password" placeholder="პაროლი" />
                        <ErrorMessage class="text-danger" name="password" style="font-size: 14px;" />
                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12">
                        <label for="password_confirmation" class="form-label">გაიმეორე პაროლი</label>
                        <Field v-model="form.password_confirmation" type="password" name="password_confirmation" class="form-control cast-input" id="password_confirmation" placeholder="გაიმეორე პაროლი" />
                        <ErrorMessage class="text-danger" name="password_confirmation" style="font-size: 14px;" />
                    </div>
                    <!-- End Col -->

                     <!-- Col -->
                     <div class="col-12 d-flex align-items-center">
                        <label class="d-inline-block me-2 cursor-pointer" for="terms">ვეთანხმები საიტის 
                            <a target="_blank" href="https://api.crm.reachstar.io/rules/rules-reachstar.pdf"><b>წესებს</b></a>
                        </label>
                        <Field class="form-check-input cursor-pointer mt-0 me-2" type="checkbox" id="terms" :value="true" name="terms" v-model="form.terms" />
                        <ErrorMessage class="text-danger d-block w-100" name="terms">
                            <span style="font-size: 14px;" class="text-danger">დაეთანხმე წესებს</span>
                        </ErrorMessage>

                    </div>
                    <!-- End Col -->

                    <!-- Col -->
                    <div class="col-12 pt-4">
                        <div class="d-flex justify-content-center w-100">
                            <span class="d-inline-block me-2">გაქვთ ანგარიში?</span>
                            <router-link class="d-inline-block" to="/Signin">შესვლა</router-link>
                        </div>
                        <button class="btn btn-my-primary w-100 mt-2 py-3">რეგისტრაცია</button>
                    </div>
                    <!-- End Col -->
            </Form>
        </div>
        <!-- End Singnin -->

    </div>
</template>


// for Fields Validation
<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate';
const simpleSchema = {
    email(value) {
        // validate email value...
        // if the field is empty
        if (!value) {
            return 'შეიყვანეთ ელ.ფოსტა';
        }
        // if the field is not a valid email
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!regex.test(value)) {
            return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
        }
        // All is good
        return true;
    },
    name(value) {
        // validate name value...
        if (!value) {
            return 'გთხოვთ მიუთითოთ თქვენი სახელი და გვარი';
        }

        return true;
    },
    // personal_id(value, {form}) {
        
    //     if(value) {
    //         const formattedValue = value.replace(/[^0-9]/g, '');
    //         form.personal_id = formattedValue;
    //     }

    //     if (String(value).length > 11 || String(value).length < 11) {
    //         return 'გთხოვთ მიუთითო სწორად 11 ნიშნა ნომერი';
    //     }

    //     // validate name value...
    //     if (!value) {
    //         return 'აუცილებელი ველი';
    //     }

    //     return true;
    // },

    mobile(value, { form}) {

        if (value) {
            // Remove all non-digit characters
            const formattedValue = value.replace(/[^0-9]/g, '');

            // Format the value as 'xxx xx xx xx'
            const formattedMobile = formattedValue
                .replace(/(\d{3})(\d{2})?(\d{2})?(\d{2})?/, '$1 $2 $3 $4')
                .substring(0, 12);

            // Use a regular expression to check if the value matches the pattern
            const regex = /^[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}$/; // Match 'xxx xx xx xx'

            if (!regex.test(formattedMobile)) {
                return 'მობილური ნომრი უნდა იყოს ფორმატში "xxx xx xx xx"';
            }

            form.mobile = formattedMobile;

        }

        // Validate mobile number value...
        if (!value) {
            return 'აუცილებელი ველი';
        }

        return true;
    },

    password(value) {
        // validate password value...
        if (!value) {
            return 'შეიყვანეთ პაროლი';
        }

        // Add any additional password validation logic here, such as minimum length, complexity, etc.

        return true;
    },

    password_confirmation(value, { form }) {
        // validate password confirmation value...
        if (!value) {
            return 'გთხოვთ გაიმეოროთ პაროლი';
        }

        if (value !== form.password) {
            return 'პაროლები არ ემთხვევა';
        }

        return true;
    },

    terms(value) {
        // validate name value...
        if (!value) {
            return 'დაეთანხმე წესებს';
        }

        return true;
    },
}
</script>

<script>

import axios from 'axios';

export default {
    name: "Signup",
    data() {
        return {
            form: {
                name: '',
                email: '',
                // personal_id: '',
                mobile: '',
                password: '',
                password_confirmation: '',
                terms: false,
                traning_id: ''
            },
            isLoading: true
        }
    },
    methods: {
        submitForm() {
     
            const course_id = localStorage.getItem('course_id');
            this.form.traning_id = course_id;
        
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/signup', this.form)
                .then((res) => {
                    // this.$store.commit("setCurrentPermission", res.data.permission);

                    localStorage.setItem('user', JSON.stringify(res.data));
                    if (localStorage.getItem('user')) {
                        this.$router.push({
                            path: "/Dashboard"
                        })
                    }


                })
                .catch((error) => {
                    console.log(error);
                });
        }

    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 500)
    }
}
</script>

<style scoped>
    .auth {
    max-width: 500px;
    margin-inline: auto;

    background-color: #fff;
    padding: 30px;
}
.cast-input {
    background-color: #f8f5f5;
}
.recover {
    margin-top: 8px;
    display: block;

    font-size: 14px;
}
label {
    font-size: 14px;
}
.row .col-12:last-child span, .row .col-12:last-child a {
    font-size: 14px;
}
.logo {
    padding-left: 7px;
    padding-top: 7px;
}
.logo_circle {
    background-color: var(--primary-color);
    width: 39px;
    height: 39px;
    border-radius: 50%;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: 1;
}
</style>