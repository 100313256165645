import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// Vue Select Inputs Forms
import 'vue-select/dist/vue-select.css';
// bootstrap styles
import '@/assets/css/bootstrap.min.css'
// for custom styles
import '@/assets/css/style.css'
// loader css
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

import VueGtag from 'vue-gtag'

const app = createApp(App)


app.use(VueGtag, {
    config: { id: "G-L5K445L0Q5" }
})


app.use(store)
app.use(router)
app.mount('#app')
