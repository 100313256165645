<template>
    <div class="my-5 p-3 p-sm-0">
        <div class="background-animation"></div>
        <div class="loader" v-if="isLoading">
          <img src="@/assets/loader.gif">
        </div>

        <!-- Singnin -->
        <div class="auth">
            <form class="row g-3">

                <!-- Col -->
                <div class="col-12">
                    <router-link to="/" class="logo d-block position-relative">
                        <!-- <div class="logo_circle placeholder-wave"></div> -->
                        <img style="width: 200px;" src="@/assets/image/dklogo.png" alt="logo">

                    </router-link>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 mt-5">
                    <h4 class="fs-4">პაროლის აღდგენა</h4>
                    <p class="text-secondary">პაროლის აღდგენის ლინკი მოგივათ მითითებულ ელ.ფოსტაზე</p>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12">
                    <label for="email" class="form-label">ელ.ფოსტა</label>
                    <input type="email" class="form-control cast-input" id="email" placeholder="მიუთითე შენი ელ.ფოსტა">
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 pt-4">
                    <div class="d-flex justify-content-center w-100">
                        <span class="d-inline-block me-2">არ გაქვს ანგარიში?</span>
                        <router-link class="d-inline-block" to="/Signup">რეგისტრაცია</router-link>
                    </div>
                    <button type="submit" class="btn btn-my-primary w-100 mt-2 py-3">აღდგენა</button>
                </div>
                <!-- End Col -->
            </form>
        </div>
        <!-- End Singnin -->

    </div>
</template>

<script>
export default {
    name: "Recover",
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 500)
    }
}
</script>

<style scoped>
.auth {
    max-width: 500px;
    margin-inline: auto;

    background-color: #fff;
    padding: 30px;
}

.cast-input {
    background-color: #f8f5f5;
}

.recover {
    margin-top: 8px;
    display: block;

    font-size: 14px;
}

label {
    font-size: 14px;
}

.row .col-12:last-child span, .row .col-12:last-child a {
    font-size: 14px;
}
.logo {
    padding-left: 7px;
    padding-top: 7px;
}
.logo_circle {
    background-color: var(--primary-color);
    width: 39px;
    height: 39px;
    border-radius: 50%;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: 1;
}
</style>