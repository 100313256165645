import { createStore } from 'vuex'
import axios from 'axios';
import router from '@/router/index.js';

export default createStore({
  state: {
    permission: ''
  },
  getters: {
  },
  mutations: {
    setCurrentPermission(state, permission) {
      state.permission = permission;
    }
  },
  actions: {
    check_user() {
      if (localStorage.getItem("user")) {
        const token = JSON.parse(localStorage.getItem('user')).access_token;
        axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/check-user', { 'data': localStorage.getItem('user') }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {

            })
            .catch((error) => {

                localStorage.removeItem('user');
                router.push({ path: '/Signin' })

            });
        }
    }
  },
  modules: {
  }
})
