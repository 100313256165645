<template>
    <div>
        <div class="background-animation"></div>
        <div class="loader" v-if="isLoading">
          <img src="@/assets/loader.gif">
        </div>

        <Header />
        <!-- Course Inner -->
        <div class="container mt-150 mb-200">
            <!-- Row -->
            <div class="row">
                <!-- Col -->
                <div class="col-12 text-center" v-if="!isLoading" >
                    <div class="alert alert-success py-4 fs-5" role="alert">
                        თქვენი გადახდა წარმატებით განცხორციელდა
                    </div>

                    <router-link class="btn btn-my-primary mt-3" to="/">მთავარი გვერდი</router-link>
                </div>
                <!-- End Col -->
            </div>
            <!-- End Row -->
        </div>
        <!-- End Course Inner -->
        <Footer :class="{'pt-100': isLoading}" />
    </div>
</template>
    
    
<script>
// Imported Components
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: "success",
    data() {
        return {
            isLoading: true
        }
    },
    components: {
        Header,
        Footer
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 2000)
    }
}
</script>
    
<style scoped>
</style>