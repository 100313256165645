<template>
    <div>
        <!-- Video -->
        <div v-if="path && path == 'Home'" class="position-relative">
            <div class="video">
                <video style="height: 100%;" loop="" muted="" autoplay=""
                    playsinline="">
                    <source src="https://academy.dk.ge/main_video.mp4" type="video/mp4">
                    <source src="https://academy.dk.ge/main_video.mp4" type="video/mp4">
                </video>
               
            </div>
            <div class="home-height"></div>
            <div class="video-content">
                <h1 class="video_text">გახდი შენი თავის საუკეთესო ვერსია</h1>
                <a href="#programs" class="btn btn-my-primary mt-3 placeholder-wave">  
                    <div class="d-inline-flex column-gap-2 position-relative">
                        პროგრამები
                        <i class="bi bi-rocket"></i>
                        <div class="fire">
                            <div class="red flame"></div>
                            <div class="orange flame"></div>
                            <div class="yellow flame"></div>
                            <div class="white flame"></div>
                            <!-- <div class="blue circle"></div> -->
                            <!-- <div class="black circle"></div> -->
                        </div>
                    </div>
    
                </a>
            </div>
        </div>
        <!-- End Video -->

        <!-- Navbar -->
        <nav :class="isScrolled >= 10 ? 'bg-navbar' : ''"
            class="navbar navbar-expand-lg navbar-light fixed-top pt-4 pb-4">
            <div class="container">
                <a class="logo d-block position-relative" href="/">
                    <!-- <div class="logo_circle placeholder-wave"></div> -->
                    <img v-if="isScrolled < 10 && path == 'Home'" style="width: 200px;" src="../assets/image/white_logo.png" alt="logo">
                    <img v-else style="width: 200px;" src="../assets/image/logo.svg" alt="logo">

                </a>
                <button class="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <svg v-if="isScrolled < 10 && path == 'Home'" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z"
                            fill="#fff" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 8C4 7.44772 4.44772 7 5 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H5C4.44772 9 4 8.55228 4 8Z"
                            fill="#fff" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 24C4 23.4477 4.44772 23 5 23H20C20.5523 23 21 23.4477 21 24C21 24.5523 20.5523 25 20 25H5C4.44772 25 4 24.5523 4 24Z"
                            fill="#fff" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z"
                            fill="#2B2B2B" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 8C4 7.44772 4.44772 7 5 7H20C20.5523 7 21 7.44772 21 8C21 8.55228 20.5523 9 20 9H5C4.44772 9 4 8.55228 4 8Z"
                            fill="#2B2B2B" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 24C4 23.4477 4.44772 23 5 23H20C20.5523 23 21 23.4477 21 24C21 24.5523 20.5523 25 20 25H5C4.44772 25 4 24.5523 4 24Z"
                            fill="#2B2B2B" />
                    </svg>
                </button>
                <!-- Menu -->
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav align-items-center ms-auto mb-2 mb-lg-0">
                        <li v-if="user" class="nav-item">
                            <router-link class="nav-link" to="/Dashboard">
                                ჩემი გვერდი
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://dk.ge/Home" target="_blank"><span
                                    class="text-custom-primary" :class="isScrolled < 10 && path == 'Home' ? 'text-white' : ''">ვაკანსიები</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="isScrolled < 10 && path == 'Home' ? 'text-white' : ''" href="/#programs">პროგრამები</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" :class="isScrolled < 10 && path == 'Home' ? 'text-white' : ''" href="/#offers">რატომ ჩვენ?</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" :class="isScrolled < 10 && path == 'Home' ? 'text-white' : ''" href="#Footer">კონტაქტი</a>
                        </li>


                        <!-- Signup -->
                        <!-- <router-link v-if="!user" to="/Signup" class="btn btn-my-primary placeholder-wave">  
                            
                        <div class="d-flex column-gap-2 position-relative">
                            დარეგისტრირდი
                            <i class="bi bi-rocket"></i>
                            <div class="fire">
                                <div class="red flame"></div>
                                <div class="orange flame"></div>
                                <div class="yellow flame"></div>
                                <div class="white flame"></div>
                            </div>
                        </div>
        
                    </router-link> -->
                        <!-- End Signup -->

                        <li v-if="user" class="nav-item ms-3">
                            <div class="dropdown">
                                <button class="border-0 bg-transparent p-0 m-0 dropdown-toggle placeholder-wave"
                                    type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span
                                        class="d-inline-block rounded-circle bg-custom-primary text-white text-center me-1"
                                        style="width: 45px; height: 45px;">
                                        <span class="d-flex w-100 h-100 justify-content-center align-items-center">{{
                                            profileName }}</span>
                                    </span>
                                    {{ name }}
                                </button>
                                <ul class="dropdown-menu mt-2">
                                    <!-- <li class="w-100 px-3 pb-2"><span class="fw-bold">ბალანსი:</span> 0₾</li> -->
                                    <li v-if="1 != 1" class="text-center">
                                        <button type="button" class="btn btn-light position-relative mb-0 mt-1">
                                            ჩემი ბალანსი:
                                            <span
                                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                                                0₾
                                            </span>
                                        </button>
                                        <div class="divide border-bottom w-100 my-2"></div>
                                    </li>
                                    <li><router-link class="dropdown-item" to="/Dashboard"
                                            style="font-family: var(--second-font) !important;"><i
                                                class="bi bi-columns-gap me-3"></i> ჩემი გვერდი</router-link></li>
                                    <li v-if="1 != 1"><router-link class="dropdown-item" to="/All_Course"><i
                                                class="bi bi-bag-heart me-3"></i> პროგრამები</router-link></li>
                                    <!-- <li><router-link class="dropdown-item" to="#" style="font-family: var(--second-font) !important;"><i class="bi bi-person-lines-fill me-3"></i> პროფილი</router-link></li> -->
                                    <li><button class="dropdown-item" @click="logout()"><i
                                                class="bi bi-box-arrow-right me-3"></i> გასვლა</button></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- End Menu -->
            </div>
        </nav>
        <!-- End Navbar -->

        <!-- Canvas Mobile Menu -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                    <a class="logo d-block position-relative" href="/">
                        <!-- <div class="logo_circle placeholder-wave"></div> -->
                        <img style="width: 200px;" src="../assets/image/logo.svg" alt="logo">
                    </a>
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <li v-if="user" class="nav-item border-bottom pb-3 mb-3">
                        <div class="d-flex align-items-center justify-content-between mb-4">
                            <h1 class="nav-link fw-bold mb-0 pb-0">{{ name }}</h1>
                            <button v-if="1 != 1" type="button" class="btn btn-light position-relative mb-0">
                                ჩემი ბალანსი:
                                <span
                                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                                    0₾
                                </span>
                            </button>
                        </div>

                        <router-link class="nav-link py-0 my-2" to="/Dashboard"><span class="d-block w-100 h-100"
                                data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-columns-gap me-3"></i>
                                ჩემი გვერდი</span></router-link>
                        <router-link v-if="1 != 1" class="nav-link py-0 my-2" to="/All_Course"><span
                                class="d-block w-100 h-100" data-bs-dismiss="offcanvas" aria-label="Close"><i
                                    class="bi bi-bag-heart me-3"></i> პროგრამები</span></router-link>
                        <!-- <router-link class="nav-link py-0 my-2" to="/"><span class="d-block w-100 h-100" data-bs-dismiss="offcanvas" aria-label="Close" style=""><i class="bi bi-person-lines-fill me-3"></i> პროფილი</span></router-link> -->
                        <button class="nav-link py-0 my-2" @click="logout()"><span class="d-block w-100 h-100"
                                data-bs-dismiss="offcanvas" aria-label="Close"><i
                                    class="bi bi-box-arrow-right me-3"></i> გასვლა</span></button>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link"  href="https://dk.ge/" target="_blank"><span
                                class="text-custom-primary">ვაკანსიები</span></a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                        <router-link class="nav-link" to="/All_Course">პროგრამები</router-link>
                    </li>
                    <!-- <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                        <a class="nav-link" href="/#offers">რატომ ჩვენ?</a>
                    </li> -->
                    <li class="nav-item me-5" data-bs-dismiss="offcanvas" aria-label="Close">
                        <a class="nav-link" href="#Footer">კონტაქტი</a>
                    </li>

                    <li v-if="!user" class="nav-item mt-5 d-none">
                        <!-- Signup -->
                        <a href="/Signup" class="btn btn-my-primary">

                            <div class="d-flex column-gap-2 position-relative">
                                დარეგისტრირდი
                                <i class="bi bi-rocket"></i>
                                <div class="fire">
                                    <div class="red flame"></div>
                                    <div class="orange flame"></div>
                                    <div class="yellow flame"></div>
                                    <div class="white flame"></div>
                                    <!-- <div class="blue circle"></div> -->
                                    <!-- <div class="black circle"></div> -->
                                </div>
                            </div>

                        </a>
                        <!-- End Signup -->
                    </li>
                </ul>
            </div>
        </div>
        <!-- End Canvas Mobile Menu -->
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: 'Header',
    data() {
        return {
            isScrolled: false,
            user: false,

            name: '',
            profileName: '',
            path: this.$route.name
        };
    },
    methods: {
        // Tracks the scroll position
        handleScroll() {
            this.isScrolled = window.pageYOffset;
        },
        logout() {
            localStorage.removeItem('user');
            this.$router.push({
                path: "/"
            })

            if(this.$route.name == 'Home') {
                window.location.reload();
            }
        },

        check_user() {
            return this.$store.dispatch('check_user');
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);

        // this.check_user();

        //* For Profile
        if (JSON.parse(localStorage.getItem('user'))) {
            var profileName = JSON.parse(localStorage.getItem('user')).user.name;
            const nameParts = profileName.split(' ');
            const firstName = nameParts[0];
            const lastName = nameParts.slice(1).join(' ');
            this.profileName = firstName && lastName ? firstName[0] + '' + lastName[0] : ''
                || firstName && !lastName ? firstName[0] : '';

        }

        this.user = localStorage.getItem('user') ? localStorage.getItem('user') : '';
        this.name = profileName ? profileName : '';
    },
    components: { router }
}
</script>

<style scoped>
.navbar-expand-lg .navbar-nav .nav-link {
    font-weight: 900;
}
.logo {
    padding-left: 7px;
    padding-top: 7px;
}
.logo_circle {
    background-color: var(--primary-color);
    width: 39px;
    height: 39px;
    border-radius: 50%;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: -1;
}

.video {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-color: #000;
}

video {
    width: 100%;
    object-fit: cover;
}

.home-height {
    min-height: 70vh;
    z-index: -1;
    position: relative;
}

.video-content {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.video_text {
    text-align: center;
    color: #fff;
}

@media screen and (max-width:768px) {
    .video_text {
        font-size: 20px;
    }
    .video-content {
        width: 100%;
    }
}

</style>
