<template>
    <div class="my-5 p-3 p-sm-0">
        <div class="background-animation"></div>
        <div class="loader" v-if="isLoading">
          <img src="@/assets/loader.gif">
        </div>

        <!-- Singnin -->
        <div class="auth">
            <Form class="row g-3" @submit="submitForm" :validation-schema="simpleSchema">

                <!-- Col -->
                <div class="col-12">
                    <router-link to="/" class="logo d-block position-relative">
                        <!-- <div class="logo_circle placeholder-wave"></div> -->
                        <img style="width: 200px;" src="@/assets/image/dklogo.png" alt="logo">

                    </router-link>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 mt-5">
                   <h4 class="fs-4">ავტორიზაცია</h4>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12">
                    <label for="email" class="form-label">ელ.ფოსტა</label>
                    <Field v-model="form.email" type="email" name="email" class="form-control cast-input" id="email" placeholder="ელ.ფოსტა" />
                    <ErrorMessage class="text-danger" name="email" style="font-size: 14px;" />
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12">
                    <label for="password" class="form-label">პაროლი</label>
                    <Field v-model="form.password" type="password" name="password" class="form-control cast-input" id="password" placeholder="პაროლი" />
                    <ErrorMessage class="text-danger" name="password" style="font-size: 14px;" />
                    <router-link to="/Recover" class="recover">პაროლის აღდგენა</router-link>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div class="col-12 pt-4">
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <span class="d-inline-block me-2">არ გაქვს ანგარიში?</span>
                        <router-link class="d-inline-block" to="/Signup">რეგისტრაცია</router-link>
                    </div>
                    <button type="submit" class="btn btn-my-primary w-100 mt-2 py-3">შესვლა</button>
                </div>
                <!-- End Col -->
            </Form>
        </div>
        <!-- End Singnin -->

    </div>
</template>

// for Fields Validation
<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate';
const simpleSchema = {
    email(value) {
        // validate email value...
        // if the field is empty
        if (!value) {
            return 'შეიყვანეთ ელ.ფოსტა';
        }
        // if the field is not a valid email
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!regex.test(value)) {
            return 'გთხოვთ მიუთითოთ სწორი ელ.ფოსტა';
        }
        // All is good
        return true;
    },

    password(value) {
        // validate password value...
        if (!value) {
            return 'შეიყვანეთ პაროლი';
        }

        // Add any additional password validation logic here, such as minimum length, complexity, etc.

        return true;
    }
}
</script>

<script>

// Axios
import axios from 'axios';

export default {
    name: "Signin",
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            isLoading: true
        }
    },
    methods: {
        submitForm() {
            axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/login', this.form)
                .then((res) => {
                    this.$store.commit("setCurrentPermission", res.data.permission);

                    localStorage.setItem('user', JSON.stringify(res.data));
                    if (localStorage.getItem('user')) {
                        this.$router.push({
                            path: "/Dashboard"
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 500)
    }
}
</script>

<style scoped>
.auth {
    max-width: 500px;
    margin-inline: auto;

    background-color: #fff;
    padding: 30px;
}
.cast-input {
    background-color: #f8f5f5;
}
.recover {
    margin-top: 8px;
    display: block;

    font-size: 14px;
}
label {
    font-size: 14px;
}
.row .col-12:last-child span, .row .col-12:last-child a {
    font-size: 14px;
}

.logo {
    padding-left: 7px;
    padding-top: 7px;
}
.logo_circle {
    background-color: var(--primary-color);
    width: 39px;
    height: 39px;
    border-radius: 50%;

    position: absolute;
    top: 2px;
    left: 0;
    z-index: 1;
}
</style>