<template>
  <router-view />
</template>

<script>
export default {
  methods: {
    check_user() {
      return this.$store.dispatch('check_user');
    }
  },
  mounted() {
    this.check_user();
  }
}
</script>

<style lang="scss">
</style>
