<template>
    <section class="mt-150">
        <div class="background-animation"></div>
        <div class="loader" v-if="isLoading">
          <img src="@/assets/loader.gif">
        </div>

        <!-- Sounds -->
        <!-- <audio v-if="audio_1" style="position: absolute;visibility: hidden;" class="test" autoplay controls>
            <source src="../../../assets/sounds/cheer.mp3">
        </audio> -->
        <!-- Course Inner -->
        <div class="container mt-100 mb-100">
            <!-- Row -->
            <div class="row gy-4">

            <div class="col-md-12">
                <h5 class="head-title">პროგრამები</h5>
            </div>

            <!-- Col -->
            <div v-for="(item, index) in courses" :key="index" class="col-md-4">
                <div class="card shadow-sm border-0 rounded-4 bordered p-4">
                    <img class="card-img" :src="item.file" alt="Front-end">
                    <h5 class="card-name mt-4">{{ item.name }}</h5>
                    <p class="card-desc pb-1" v-html="item.short_desc"></p>
                    <button @click="dynamicRoute(item.name, item.id)" class="btn btn-my-primary placeholder-wave">  
                            
                        <div class="d-inline-flex column-gap-2 position-relative">
                            სრულად ნახვა
                            <i class="bi bi-rocket"></i>
                            <div class="fire">
                                <div class="red flame"></div>
                                <div class="orange flame"></div>
                                <div class="yellow flame"></div>
                                <div class="white flame"></div>
                                <!-- <div class="blue circle"></div> -->
                                <!-- <div class="black circle"></div> -->
                            </div>
                        </div>
        
                    </button>
                </div>
            </div>
            <!-- End Col -->

            </div>
            <!-- End Row -->
        </div>
        <!-- End Course Inner -->
    </section>
</template>

<script>

// Axios
import axios from 'axios';

export default {
    name: "All_Courses",
    data() {
        return {
            courses: [],
            audio_1: false,
            isLoading: true
        }
    },
    methods: {
        getCourses() {
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/traning/LoadData')
                .then((res) => {
                    this.courses = res.data;

                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        play() {
            this.audio_1 = true;
            // this.audio_2 = false;
        },
        stop() {
            this.audio_1 = false;
            // this.audio_2 = true;
            // setTimeout(() => {
            //     this.audio_2 = false
            // }, 2000)
        },

        dynamicRoute(name, id) {

            // Replace "%20" with underscores
            // var item_name = name.replace(/ /g, "-");

            this.$router.push({
                path: `/Course/${id}`
            })
        }
    },
    mounted() {
         // for animation
        //  setTimeout(() => {
        //     const btn = document.querySelectorAll('.btn-my-primary');
        //     btn.forEach(e => {
        //         e.addEventListener('mouseenter', this.play);
        //         e.addEventListener('mouseleave', this.stop);
        //     })
        // }, 1000);

        // scroll to the top
        window.scrollTo(0, 0);

        // get data of courses
        this.getCourses();
    },
    updated() {
        // let audio = document.querySelectorAll("audio");
        // audio.forEach(e => {
        //     e.volume = 0.05
        // })
    }
}
</script>


<style scoped>

</style>