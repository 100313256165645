<template>
    <div>
        <Header />
        <All_Courses />
        <Footer />
    </div>
</template>
    
    
<script>
// Imported Components
import Header from '@/components/Header.vue'
import All_Courses from './All_Courses.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: "Index",
    components: {
        Header,
        All_Courses,
        Footer
    }
}
</script>
    
<style scoped>
</style>