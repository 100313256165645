<template>
      <div>
        <div class="background-animation"></div>

        <div class="loader" v-if="isLoading">
          <img src="@/assets/loader.gif">
        </div>
        <!-- Course Inner -->
        <div class="container mt-150 mb-100">
            <!-- Row -->
            <div class="row gy-4"  v-if="!isLoading">
                <!-- Col -->
                <div class="col-12">
                   <!-- Row -->
                   <div class="row g-3">
                        <!-- Col -->
                        <div class="col-md-8">
                            <h5 class="head-title">ჩემი კურსები</h5>
                        </div>

                        <div class="col-md-4">
                            <h5 class="head-title">კალათა</h5>
                        </div>
                        <!-- End Col -->
                    </div>
                    <!-- End Row -->

                  <div class="row g-3" >
                      <div class="col-md-8" >
                        <div class="row">
                          <div class="col-md-12" v-if="!paid_tranings">
                            <div class="alert alert-warning">
                              თქვენ არ გაქვთ შეძენილი კურსი.
                            </div>
                          </div>

                            <!-- Col -->
                            <div v-if="paid_tranings" v-for="(item, index) in paid_tranings" class="col-md-6">
                              <div class="card shadow-sm border-0 rounded-4 bordered p-4">
                                <img :src="item.Tranings.image" alt="Front-end">
                                <h5 class="mt-4">{{ item.Tranings.name }}</h5>
                                <small class="mb-2 mt-3">
                                  <span class="fw-bold d-block mb-1">ადმინისტრატორის ნომერი</span>
                                  <i class="bi bi-telephone-fill me-2 text-custom-primary"></i>  <a href="tel:+995558347347" target="_blank">558 347 347</a>
                                </small>
                                <small>
                                  <span class="fw-bold d-block mb-1">Discord არხი</span>
                                  <i class="bi bi-discord me-2 text-custom-primary"></i> <a class="case_on" href="https://discord.gg/bZstzd2Y" target="_blank">ჯგუფში გაწევრიანება</a>
                                </small>

                                <button class="btn btn-my-primary mt-4" @click="calendar()">შეხვედრის ჩანიშვნა <i class="bi bi-calendar2-check ms-2"></i></button>

                                <router-link :to="'Course/'+item.Tranings.name+'/'+item.traning_id" class="btn btn-my-success mt-2">

                                  <div class="d-inline-flex column-gap-2 position-relative">
                                    კურსის შესახებ
                                    <i class="bi bi-rocket"></i>
                                    <div class="fire">
                                      <div class="red flame"></div>
                                      <div class="orange flame"></div>
                                      <div class="yellow flame"></div>
                                      <div class="white flame"></div>
                                      <!-- <div class="blue circle"></div> -->
                                      <!-- <div class="black circle"></div> -->
                                    </div>
                                  </div>

                                </router-link>
                              </div>
                            </div>
                            <!-- End Col -->
                        </div>

                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="alert alert-warning"  v-if="!list">
                              თქვენ კალათა ცარიელია.
                            </div>
                              <!-- Col -->

                              <div class="col-md-12"  v-if="list">
                                <div  class="card shadow-sm border-0 rounded-4 bordered p-4" v-if="list.Tranings">

                                  <!-- Row -->
                                  <div class="row"  v-if="list">
                                    <!-- Col -->
                                    <div class="col-3"  v-if="list.Tranings.name">
                                      <img class="w-100 border rounded" :src="list.Tranings.image" :alt="list.Tranings.name"  v-if="list.Tranings.image">
                                    </div>
                                    <!-- End Col -->

                                    <!-- Col -->
                                    <div class="col-9">
                                      <div class="d-flex flex-column justify-content-between h-100 py-0">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <h5 class="mb-0">{{ list.Tranings.name }}</h5>
                                          <h5 class="fw-bold fs-3 mb-0">{{ list.Tranings.price }}₾</h5>
                                        </div>
                                        <div>
                                          <span class="cursor-pointer text-danger" @click="delete_by_id(list.id)"><i class="bi bi-trash"></i> წაშლა</span>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- End Col -->
                                  </div>
                                  <!-- End Row -->
                                </div>
                              </div>
                              <!-- End Col -->

                              <div class="col-12 mt-3 "  v-if="list">
                                <button type="button"  @click="pay()" class="btn btn-my-success float-end"><i class="bi bi-rocket"></i> გადახდა</button>
                              </div>

                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <!-- End Col -->
            </div>
            <!-- End Row -->

            <!-- Row -->
            <div class="row gy-4 mt-5" v-if="!isLoading">
                <!-- Col -->
                <div class="col-12 d-flex flex-wrap row-gap-3 align-items-center justify-content-between">
                    <h5 class="head-title mb-0">ჩემი ტრანზაქციები</h5>
                    <button v-if="1 != 1" type="button" class="btn btn-light position-relative mb-0">
                        ჩემი ბალანსი:
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                            0₾
                        </span>
                    </button>
                </div>
                <!-- End Col -->

                <!-- Col -->
                <div v-if="transactions" class="col-12">
                    <!-- Table of Transactions -->
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="bg-white">
                                <tr class="align-top">
                                    <th scope="col">ტრანზაქციის ID</th>
                                    <th scope="col">კურსი</th>
                                    <th scope="col">შექმნის თარიღი</th>
                                    <th scope="col">ღირებულება</th>
                                    <th scope="col">გადახდის თარიღი</th>
                                    <th scope="col">გადახდილი თანხა</th>
                                    <th scope="col">სტატუსი</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Transaction -->
                                <tr class="align-middle" v-for="(item, index) in transactions" :key="index">
                                    <th scope="row">{{ item.transactionId }}</th>
                                    <td>{{ item.Tranings.name }}</td>
                                    <td>{{ item.created_at }}</td>
                                    <td>{{ item.Amount }}₾</td>
                                    <td>{{ item.updated_at }}</td>
                                    <td>{{ item.Amount }}₾</td>
                                    <td>
                                      <span class="badge text-bg-danger" v-if="item.payment_status=='unpaid'">გადაუხდელი</span>
                                      <span class="badge text-bg-success" v-if="item.payment_status=='paid'">გადახდილი</span>
                                    </td>
                                </tr>
                                <!-- End Transaction -->
                                <!-- End Transaction -->
                            </tbody>
                        </table>
                    </div>
                    <!-- End Table of Transactions -->
                </div>
                <!-- End Col -->
            </div>
            <!-- End Row -->
        </div>
        <!-- End Course Inner -->
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Dashboard",
    data() {
      return {
        list:{
          Tranings:{}
        },
        paid_tranings:{},
        transactions:{},
        isLoading:true
      }
    },
    methods: {
        calendar() {
            Calendly.showPopupWidget('https://calendly.com/g-katsarava/test');return false;
        },
        getList(){
          this.isLoading=true;
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL +'/auth/Cart/GetList',{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
              .then((res) => {
                this.list = res.data;
                this.isLoading=false;
              })
              .catch((error) => {
                console.log(error);
                this.isLoading=false;
              });
        },
        delete_by_id(itemId){
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.delete(process.env.VUE_APP_BACKEND_URL + '/auth/Cart/delete/'+itemId, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
              .then(response => {
                this.getList();
              })
              .catch((error) => {

              });
        },
        pay(){
          this.isLoading= true;
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.post(process.env.VUE_APP_BACKEND_URL + '/auth/Cart/pay', { },{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
              .then(response => {
                this.isLoading= false;
                window.location.href=response.data;
              })
              .catch((error) => {

              });
        },
        getPaidList(){
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL +'/auth/Cart/Paid',{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
              .then((res) => {
                this.paid_tranings = res.data;
              })
              .catch((error) => {
                console.log(error);
              });
        },
        getTransactionList(){
          const token = JSON.parse(localStorage.getItem('user')).access_token;
          axios.get(process.env.VUE_APP_BACKEND_URL +'/auth/Cart/Transactions',{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
              .then((res) => {
                this.transactions = res.data;
              })
              .catch((error) => {
                console.log(error);
              });

        }

    },
    mounted() {
      this.getList();
      this.getPaidList();
      this.getTransactionList();
      window.scrollTo(0, 0);
    }
}
</script>

<style scoped>

</style>